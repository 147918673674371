import React from "react"
import Link from "gatsby-link"
import BlogLayout from '../components/BlogLayout'
import Seo from "../components/SEO"
import { graphql } from "gatsby"

export default class BlogPostTemplate  extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { frontmatter, html } = post
    const { previous, next } = this.props.pageContext

    return (
      <BlogLayout>
        <div className="blog-post-container">
          <Seo
            postData={post}
            isBlogPost
          />
          <div className="blog-post">
            <h1>{frontmatter.title}</h1>
            <small>{frontmatter.date}</small>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
          <ul
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              listStyle: 'none',
            }}
          >
            <li>
              {previous && (
                <Link to={previous.frontmatter.path} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.frontmatter.path} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </div>
      </BlogLayout>
    );
  }
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;